













import { Component, Vue } from "vue-property-decorator";

import BlogPostAppModel from "@/api/blog_post.app.model";

import PostsCarousel from "@/layouts/components/blog/user/PostsCarousel.vue";

import { BlogPost, IdealMktCategories } from "@/interfaces/blog_post";
import { removeDiacritics } from "@/helpers";
import { inflateEntity } from "@/api/_request";

interface PostsByCategoryProps {
	id: string;
	data: BlogPost[];
	sectionTitle: string;
}

@Component({ components: { PostsCarousel } })
export default class BlogPosts extends Vue {
	blogPostModel = BlogPostAppModel;

	posts: BlogPost[] = [];

	get postsByCategory() {
		const categories: string[] = Object.values(IdealMktCategories);

		const postsByCategory: PostsByCategoryProps[] = categories.map((category, index) => ({
			id: "carousel-" + index,
			data: this.posts.filter(post => removeDiacritics(post.mainCategory.name) === removeDiacritics(category)),
			sectionTitle: category,
		}));

		postsByCategory.unshift({
			id: "carousel-recent-posts",
			sectionTitle: "Novas publicações",
			data: this.posts.sort((postA, postB) => {
				return new Date(postA.createdDate ?? 0).getTime() < new Date(postB.createdDate ?? 0).getTime() ? 1 : -1;
			}),
		});

		return postsByCategory.filter(posts => posts.data.length);
	}

	mounted() {
		this.loadPosts();
	}

	async loadPosts() {
		this.$store.dispatch("app/showLoading");

		try {
			const posts = (await this.blogPostModel.search({
				join: { field: "mainCategory" },
				sort: [
					{
						field: "eventDate",
						order: "ASC",
					},
					{
						field: "createdDate",
						order: "DESC",
					},
				],
			})) as BlogPost[];

			this.posts = posts.map(post => inflateEntity(post) as BlogPost);
		} catch (error) {
			console.error(error);
		}

		this.$store.dispatch("app/hideLoading");
	}
}
